<template>
	<basePopup :cancelFunc="cancel" :okFunc="goGraduate" :title="title" okText="수료">
		<h6 class="inline-block">판매 결과</h6>
		<b-button
			class="p-0 ml-2 -top-0.5"
			v-b-tooltip.hover.html
			size="sm"
			title="* 배송상태 기준: 결제완료, 주문확인, 배송중, 배송대기, 배송완료, 교환요청중, 교환완료, 구매확정,배송지연<br>* 판매금액 산식: 주문수량*가격 (배송비 제외)"
			variant="no"
		>
			<b-icon icon="info-circle" />
		</b-button>

		<b-card class="mb-4">
			<tb :inf="inf" :res="res" nav="none" />
			<vtb id="graduateTb" :data="vInf" oneColumn titleSize="3">
				<template #slot="{ v }">
					<p v-text="`${comma(v.cont1)}개`" />
					<p v-text="`${comma(v.cont2)}원`" />
				</template>
			</vtb>
		</b-card>

		<b-card>
			<p class="fw-700 mb-2">아래 정보를 모두 선택 및 입력해야 수료됩니다.</p>
			<div class="flex">
				<p class="w-1/6">콘텐츠 카테고리</p>
				<div class="w-5/6">
					<selectList :itemList="categoryOpts" :pr="ths" />
					<b-form-checkbox
						class="mt-2 inline-block"
						v-model="input.isSales"
						v-b-tooltip.hover.html
						title="판매계정 기준: 최근 피드 30개 중 90% 이상 광고/협찬 판매와 관련됨"
						>판매계정 여부 <b-icon font-scale="1" icon="info-circle"
					/></b-form-checkbox>
				</div>
			</div>
			<div class="flex mt-2">
				<p class="w-1/6">콘텐츠 퀄리티</p>
				<b-form-radio
					v-for="v in qualityOpts"
					class="mr-6"
					v-model="input.quality"
					name="qualityRadio"
					:value="v.value"
				>
					{{ v.label }}
				</b-form-radio>
			</div>

			<div class="flex mt-2">
				<p class="w-1/6">수료 메모</p>
				<b-form-textarea
					class="w-4/5 resize-none"
					v-model="input.memo"
					placeholder="수료 사유, 가이드 숙지 정도, 인플루언서 성향, 특징 등을 작성해 주세요."
					rows="5"
				/>
			</div>

			<div class="flex mt-2">
				<p class="w-1/6">수료 등급</p>
				<vSelect
					class="w-1/4"
					v-model="input.class"
					:clearable="false"
					:options="['1', 'a', 'b', 'c', 'd', 'e']"
				/>
			</div>
		</b-card>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import selectList from 'comp/local/selectList'

const defInput = {
	quality: '',
	isSales: false,
	memo: '',
	class: '',
}

export default {
	components: { basePopup, selectList },
	data() {
		return {
			isShow: false,
			title: '',

			res: {},

			inf: [
				{ title: '딜 차수', key: 'stage', size: 3 },
				{ title: '딜 번호', key: 'dealNum', size: 3 },
				{ title: 'idx', key: 'productGroupIdx', size: 3 },
				{ title: '딜 명', key: 'dealName', size: 20, notCenter: 1 },
				{ title: '딜 시작일', key: 'startDt', size: 6 },
				{ title: '딜 종료일', key: 'endDt', size: 6 },
				{ title: '판매량', key: 'sellCount', size: 6 },
				{ title: '판매금액', key: 'sellPrice', size: 6 },
			],
			vInf: [],

			categoryOpts: {},

			qualityOpts: [],

			input: { ...defInput },
		}
	},
	methods: {
		open(item) {
			this.input = {
				...defInput,
				memberSeq: item.memberSeq,
				hottId: item.hottId,
				class: item.hottistClass ? item.hottistClass : 'd',
			}

			this.title = `${item.name} / ${item.hottId} / ${item.currentInstaId} 핫티스트 스타터 수료`

			postApi('/starterSelect/dealList', { hottId: item.hottId }).then(res => {
				let sumCount = 0,
					sumPrice = 0
				const ct = res.list.length
				res.list.map(v => {
					;['startDt', 'endDt'].map(k => {
						v[k] = v[k].substr(0, 10)
					})
					sumCount += v.sellCount * 1
					sumPrice += v.sellPrice * 1

					v.sellCount = comma(v.sellCount) + '개'
					v.sellPrice = comma(v.sellPrice) + '원'
					return v
				})
				this.vInf = [{ title: '총합', cont1: sumCount, cont2: sumPrice, model: 'slot' }]
				this.vInf.push({
					title: '딜당 평균',
					cont1: Math.round((sumCount * 10) / ct) / 10,
					cont2: Math.round((sumPrice * 10) / ct) / 10,
					model: 'slot',
				})
				this.res = res
			})
			postApi('/hottistSelect/hottistInfo', { hottId: item.hottId }).then(res => {
				res = res.hottistInfo
				const preSelectedCategory = []
				for (let i = 1; i <= 3; i++)
					if (res['contentCategory' + i]) preSelectedCategory.push(res['contentCategory' + i])
				this.selectList.init(preSelectedCategory)

				this.input.quality = res.contentQuality
				this.input.isSales = res.salesFlag
				//수정 시 팝업 닫기 전 알럿 띄우기 위해 원본 데이터 보존
				this.hottistInfo = { ...res }
			})

			this.isShow = true
		},
		goGraduate() {
			const input = this.input,
				category = this.selectList.getSelectedItem('value')
			if (!category.length) alert.w('콘텐츠 카테고리를 선택해주세요.')
			else if (!input.quality) alert.w('콘텐츠 퀄리티를 선택해주세요.')
			else {
				const data = {
					memberSeq: input.memberSeq,
					hottId: input.hottId,
					isSales: input.isSales,
					comment: input.memo,
					contentQuality: input.quality,
					class: input.class,
					adminSeq: layout.user.seqNo,
				}
				for (let i = 0; i < 3; i++) data[`contentCategory${i + 1}`] = category[i] ? category[i] : ''
				postApi('/starterHandle/insertStarterCompleteInfo', data).then(() => {
					alert.s('스타터 수료 상태로 변경되었습니다')
					reload(this, true)
					this.isShow = false
				})
			}
		},
		cancel() {
			let isEdited = this.selectList.isEdited
			//카테고리 수정 감지
			const origin = this.hottistInfo,
				input = this.input

			if (origin.salesFlag != input.isSales) isEdited = true
			if (origin.contentQuality != input.quality) isEdited = true
			if (input.memo) isEdited = true
			if (input.class != 'd') isEdited = true

			if (isEdited)
				confirm('기존에 선택 및 작업하시던 내용이 저장되지 않습니다.\r\n창을 닫으시겠습니까?', () => {
					this.isShow = false
				})
			else this.isShow = false
		},
	},
	mounted() {
		getConfig('content_category, content_quality', false, false).then(res => {
			this.categoryOpts = res.content_category
			this.qualityOpts = res.content_quality
		})
	},
	computed: {},
}
</script>

<style lang="scss">
#graduateTb {
	margin-top: -1em;

	.th {
		width: 78% !important;
	}

	.td {
		width: 22% !important;

		p {
			display: inline-block;
			width: 50%;
			line-height: 2rem;
			padding: 0 0.5rem;
			text-align: right;

			&:first-child {
				border-right: 1px solid;
			}
		}
	}
}
</style>
