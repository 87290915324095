<!--스타터 수료 관리 페이지 메인-->
<!--스타터 첫딜 매칭이랑 너무 똑같긴 한데 이따 리뉴얼한다고 하니까 일단 합치지 않음..-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="flex">
				<div class="w-1/7">
					<h6>날짜 형식</h6>
					<vSelect v-model="opt.dateType" :clearable="false" :options="dateOpts" />
				</div>
				<div class="w-6/7 pl-4">
					<h6 class="block w-1/1">&nbsp;</h6>
					<div class="flex">
						<b-form-radio class="mr-6 top-1.5" v-model="opt.period" name="searchPeriod" value=""
							>전체기간
						</b-form-radio>
						<b-form-radio class="top-1.5" v-model="opt.period" name="searchPeriod" value="period" />

						<datePicker class="w-1/6" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker class="w-1/6" model="opt.endDate" />
					</div>
				</div>
			</div>

			<div class="flex my-2">
				<div class="w-1/7">
					<h6>진행상태</h6>
					<vSelect v-model="opt.step" :clearable="false" :options="stepOpts" />
				</div>
				<div class="w-1/7 mx-4">
					<h6>핫티스트 구분</h6>
					<vSelect v-model="opt.type" :clearable="false" :options="typeOpts" />
				</div>
				<div class="w-1/8 ml-8">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6>&nbsp;</h6>
					<div>
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							v-b-tooltip.hover.html
							@keyup.enter="search"
							title="핫티스트 통합검색 대상 필드: 핫트아이디, 이름, 휴대폰번호, 인스타아이디<br>※ 딜 정보, 메모는 제외"
						/>
					</div>
				</div>
				<div>
					<h6 class="block">&nbsp;</h6>
					<b-button class="px-10 top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="mb-4 float-right z-10" @click="excelExport(true)" variant="info"
				>딜 상세 현황 다운로드</b-button
			>
			<b-button class="mb-4 mr-4 float-right z-10" @click="excelExport(false)">엑셀 다운로드 요청</b-button>

			<tb :child="child" :inf="inf" :res="res" />
		</b-card>
		<graduatePopup :pr="ths" cName="graduatePopup" />
	</div>
</template>

<script>
import graduatePopup from 'pages/graduatePopup'

const now = new Date(),
	dateOpts = [
		{ label: '승인일', value: 'ACCEPT' },
		{ label: '딜시작일', value: 'DEALSTARTDATE' },
		{ label: '딜종료일', value: 'DEALENDDATE' },
	],
	searchKeyOpts = [
		{ label: '핫티스트 통합검색', value: '' },
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '휴대폰 번호', value: 'phone' },
		{ label: '인스타아이디', value: 'snsLink' },
		{ label: '메모', value: 'comment' },
	],
	defOpt = {
		period: 'period',
		dateType: dateOpts[0],
		startDate: new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
		endDate: now,

		step: { label: '전체', value: '' },
		type: { label: '전체', value: '' },

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}
let ths

export default {
	components: { graduatePopup },
	data() {
		return {
			dateOpts,
			searchKeyOpts,
			stepOpts: [],
			typeOpts: [],

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },

				{ title: '핫티스트 구분', key: 'hottistTypeValue', size: 4 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '이름', key: 'name', size: 3 },
				{ title: '휴대폰 번호', key: 'phone', size: 4 },
				{ title: '인스타아이디', key: 'instaLinkTag', size: 6 },

				{ title: '승인일', key: 'hottDealAcceptDt', size: 6, sortable: 1 },

				{ title: '진행상태', key: 'starterStepValue', size: 6, sortable: 1, sort: 'starterStep' },
				{
					title: '수료',
					model: 'button',
					vIf: item => item.starterStep >= 5,
					size: 3,
					text: '수료',
					bVariant: 'info',
					func: this.openGraduatePopup,
				},
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 3 },
				{ title: '판매현황', model: 'button', size: 3, icon: 'reception4', func: this.openSellResult },
			],
			child: {
				inf: [
					{ title: '딜 차수', key: 'stage', size: 3 },
					{ title: '딜 번호', key: 'dealNum', size: 3 },
					{ title: '딜명', key: 'dealName', size: 10, notCenter: 1 },
					{ title: '딜 시작일', key: 'startDt', size: 6 },
					{ title: '딜 종료일', key: 'endDt', size: 6 },
					{ title: '판매량', key: 'sellCount', size: 4 },
					{ title: '판매금액', key: 'sellPrice', size: 4 },
				],
				tbClass: 'w-1/2 mx-auto',
				nav: 'none',
				changePage(item, page = 1) {
					//기존 딜 리스트를 조회한다
					const data = item.child.lastOpt
					//data.paging.pageNo = page - 1

					postApi('/starterSelect/dealList', data).then(res => {
						//받아오는 데 성공했으면 포맷 조정
						res.list = res.list.map((v, i) => {
							v.idx = v.stage
							v.startDt = v.startDt.substr(0, 10)
							v.endDt = v.endDt.substr(0, 10)
							v.sellCount = comma(v.sellCount)
							v.sellPrice = comma(v.sellPrice)
							return v
						})
						item.child.title = `${item.memberName}의 판매현황`
						item.child.res = res
					})
				},
			},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				startDate = opt.startDate.get_time('d', 0, ''),
				endDate = opt.endDate.get_time('d', 0, ''),
				starterStep = getDropdownValue(opt.step),
				hottistType = getDropdownValue(opt.type),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					orderBy: getOrder('hottDealAcceptDt'),
					paging: getPaging(),
				},
				//선택 조건에 없는 필수값들 입력
				error = []

			if (opt.period) {
				if (startDate > endDate) error.push('시작일이 종료일보다 큽니다.')
				else data = { ...data, dateType: 'ACCEPT', startDate, endDate }
			}

			if (starterStep) data.starterStep = starterStep
			if (hottistType) data.hottistType = hottistType

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (searchVal) {
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
				if (data.searchKey.length > 1) data.searchKey.splice(data.searchKey.length - 1, 1)
				//메모가 들어가면 검색이 이상해진다?
			}
			if (error.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.selectedItem = {}
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/starterSelect/starterList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.memberName = v.name
						v.memberSeq = v.seq
						v.hottDealAcceptDt = v.hottDealAcceptDt.replace('T', ' ')

						v.child = false
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport(isDealExcel = false) {
			const opt = this.lastOpt,
				ex = new excel()

			ex.isDealExcel = isDealExcel
			if (isDealExcel) ex.data.position += ' 딜상세'
			//......

			if (opt.startDate) ex.key('날짜 형식', '승인일')
			ex.date(opt.startDate, opt.endDate)

			if (opt.starterStep) ex.dropdown(opt.starterStep, this.stepOpts, '진행상태')

			if (opt.hottistType) ex.dropdown(opt.hottistType, this.typeOpts, '핫티스트 구분')

			ex.search(opt.searchKey, searchKeyOpts)

			reload(this, false, ex)
		},
		openSellResult(item) {
			//기존에 열린 다른 자식 항목은 닫기
			this.res.list.map(another => {
				if (another != item) another.child = false
			})

			if (!item.child) {
				item.child = {
					lastOpt: { hottId: item.hottId, paging: getPaging(0, 3) },
					res: [],
				}
				this.child.changePage(item, 1)
			} else item.child = false
		},
		openGraduatePopup(item) {
			this.graduatePopup.open(item)
		},
	},
	created() {
		ths = this
		getConfig('starter_step, hottist_type').then(res => {
			this.stepOpts = res.starter_step
			this.typeOpts = res.hottist_type
			this.search()
		})
		//this.search()
	},
}
</script>
